import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { isDebug } from "./api/rest";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

if (!isDebug()) {
  var firebaseConfig = {
    apiKey: "AIzaSyAAi3MhgBr3stedjohgmQmSa8wfjR4AOC8",
    authDomain: "wipdocument.firebaseapp.com",
    projectId: "wipdocument",
    storageBucket: "wipdocument.appspot.com",
    messagingSenderId: "510554230640",
    appId: "1:510554230640:web:c9f0c1a3b6669af89ac9b5",
    measurementId: "G-L0S3X9RX4D",
  };
  
}
