import React, { RefObject, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { getImageUrl } from "../api/rest";
import styled from "styled-components";
import titleImage from "../assets/title.png";
import downloadImage from "../assets/download_bt.gif";
import waitPhotoImage from "../assets/wait_paper.png";
import { ButtonBase, styled as mstyled } from "@material-ui/core";
import NotFound from "./NotFound";

const EmptyDiv = styled.div`
  position: absolute;
  width: 100vw;
  height: 100%;
  overflow: hidden;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #e2e2e4;
`;

const ContainerArea = styled.div`
  position: absolute;
  width: 100vw;
  height: 100%;
  overflow: hidden;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #e2e2e4;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const TitleImage = styled.img`
  width: 18vh;
  object-fit: contain;
  margin-bottom: 4vh;
`;

const NewpaperContainer = styled.div`
  width: 42vh;
  height: 60vh;
`;

const NewpaperImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  box-shadow: -15px 7px 10px #888888;
`;

const DownloadImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const DownloadBase = mstyled(ButtonBase)({
  width: "18vh",
  height: "5.2vh",
  marginTop: "4vh",
  overflow: "hidden",
});

function Main() {
  const imgRef: RefObject<HTMLImageElement> = useRef(null);

  const [data, setData] = useState({
    downloadComplete : false,
    image_path: waitPhotoImage,
    error: false,
  });
  const { downloadComplete , image_path, error } = data;
  const params: { id: string } = useParams();

  useEffect(() => {
    if (params.id === undefined) return;
    
    console.log("params.id : " + params.id)
    getImageUrl(params.id)
      .then((response) => {
        fetch(response.data.url, {
          method: "GET",
          headers: {
            mode: "no-cors",
            "Access-Control-Allow-Origin": "*",
          },
        })
          .then((res) => {
            res.arrayBuffer().then((buffer) => {
              const url = window.URL.createObjectURL(new Blob([buffer]));
              setData({
                ...data,
                image_path: url,
                downloadComplete : true,
              });
              console.log("download complete");
              
            });
          })
          .catch(() => {});
      })
      .catch(() => {
        setData({
          ...data,
          error: true,
        });
      });
  }, []);

  const onDownload = () => {
    if(downloadComplete === false) {
      alert("신문을 생성하고 있습니다. 잠시만 기다려 주세요.");
      return;
    }

    const file_name = `scrnews_${params.id}`;

    const link = document.createElement("a");
    link.href = image_path;
    link.setAttribute("download", file_name);
    document.body.appendChild(link);
    link.click();
  };

  if (params.id === undefined || error) return <NotFound />;
  if (image_path.length === 0) return <EmptyDiv />;

  return (
    <ContainerArea>
      <TitleImage src={titleImage} alt={titleImage}/>
      <NewpaperContainer>
        <NewpaperImage ref={imgRef} src={image_path} alt={waitPhotoImage} />
      </NewpaperContainer>
      <DownloadBase onClick={onDownload}>
        <DownloadImage src={downloadImage} alt={titleImage}/>
      </DownloadBase>
    </ContainerArea>
  );
}

export default Main;
