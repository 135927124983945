import axios from "axios";

/**
 * API 기본 주소
 * 디버그모드인 경우에는 local 서버를 바라보게끔 설정
 * 
 * 23.08.25 : 그냥 실서버를 바라보게끔 설정 (원종운)
 */
const url = "https://www.srcnews.kr";
  //process.env.NODE_ENV === "development" ? "https://192.168.1.22:44396" : "";

function encode(value: string) {
  return encodeURIComponent(value);
}

export function getURLHeader() {
  if (isDebug()) {
    return url;
  } else {
    const url = window.location.href;
    const splits = url.split("/");
    const new_url = splits[0] + "//" + splits[2];
    return new_url;
  }
}

export function getServerVersion() {
  return axios.post(`${url}/Version`);
}

export function uploadFile(name: string, file: File | null) {
  return axios.post(`${url}/DataControl/upload_data`, file, {
    auth: {
      username: "imfine",
      password: "imfine",
    },
    headers: {
      use_url_encoded: true,
      user_name: encode(name),
    },
  });
}

export function getImageFile(code: string) {
  return axios.get(`${url}/DataControl/return_data`, {
    params: {
      data_id: `${code}`,
    },
  });
}

export function getImageUrl(code: string) {
  return axios.get(`${url}/DataControl/return_url`, {
    params: {
      data_id: code,
    },
  });
}

export function checkData(code: string) {
  const form = new FormData();
  form.append("data_id", code);
  return axios.post(`${url}/DataControl/data_check`, form);
}

export function encryptString(
  username: string,
  password: string,
  keyword: string
) {
  const formData = new FormData();
  formData.append("str", keyword);
  return axios.post(`${url}/DataControl/EncryptString`, formData, {
    auth: {
      username,
      password,
    },
  });
}

export function decryptString(
  username: string,
  password: string,
  keyword: string
) {
  const formData = new FormData();
  formData.append("str", keyword);
  return axios.post(`${url}/DataControl/DecryptString`, formData, {
    auth: {
      username,
      password,
    },
  });
}

export function searchData(
  username: string,
  password: string,
  keyword: string,
  start_time: number,
  end_time: number
) {
  const formData = new FormData();
  formData.append("user_name", keyword);
  formData.append("start_time", start_time.toString());
  formData.append("end_time", end_time.toString());
  return axios.post(`${url}/DataControl/DecryptString`, formData, {
    auth: {
      username,
      password,
    },
  });
}

export function getImageData(url: string) {
  return axios.get(url);
}

export function isDebug() {
  return process.env.NODE_ENV === "development";
}
