import React from "react";
import styled from "styled-components";
import logo from "../assets/sarangchae.png";
import message from "../assets/notfound_message.png";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const LogoImage = styled.img`
  width: 15vh;
  object-fit: contain;
  margin-bottom: 8vh;
`;
const TitleImage = styled.img`
  width: 40vh;
  object-fit: contain;
`;

function NotFound() {
  console.log("NotFound");
  return (
    <Container>
      <LogoImage src={logo} />
      <TitleImage src={message} />
    </Container>
  );
}

export default NotFound;
