import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Main from "./pages/Main";
import NotFound from "./pages/NotFound";

function App() {
  return (
    <Switch>
      <Route path="/" component={Main} exact />
      <Route path="/:id" component={Main} />
      <Route path="/404" component={NotFound} />
      <Redirect to="/" />
    </Switch>
  );
}

export default App;
